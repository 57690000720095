import React from "react";
import { Button, Img, Heading } from "../../components";
import { Link } from "react-router-dom";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import PropTypes from 'prop-types';


const Foundations = ({ foundations }) => {
	const truncateText = (text, maxWords) => {
		const words = text.split(' ');
		if (words.length > maxWords) {
			return words.slice(0, maxWords).join(' ') + '...';
		}
		return text;
	};

	return (
		<div className=" bg-gray-200 w-full flex justify-center">
			<div className="flex flex-col items-center gap-y-10 justify-center max-w-[940px] w-full lg:w-[940px] py-10 px-10">
				<div className="flex flex-col gap-5 md:w-full">
					<Heading size="md" as="h2">
						You can help lots of people by donating little.
					</Heading>
					<Link to="/foundations">
						<Button color="red_300" className=" font-medium min-w-[200px] h-[40px]">
							More Foundations
						</Button>
					</Link>
				</div>

				<div className="w-full overflow-x-auto">
					<div className="grid grid-flow-col gap-5 w-full ">
						{
							foundations.map((item, index) => (
								<div
									key={ index }
									className="flex flex-col items-start bg-white-A700 min-w-[250px] w-[250px] h-full ">
									<div className="w-full h-[150px] flex justify-center border-gray-400 border-b-[0.5px] border-solid">
										<Img
											src={ item.image_url }
											alt="foundation"
											className="w-full h-[150px] object-contain"
										/>
									</div>
									<div className="flex flex-col justify-between h-full w-full p-4">
										<div className="flex flex-col gap-2 w-full h-full ">
											<Heading as="p" className="!text-xl">
												{ item.name }
											</Heading>
											<Heading
												as="p"
												className="!text-sm !font-normal !text-blue_gray-700"
												dangerouslySetInnerHTML={ { __html: truncateText(item.description, 10) } }
											/>
										</div>
										<Link to={ `/foundations/${ item.id }` } className="flex justify-center w-full mt-3">
											<Button size="sm" className="h-[40px] mb-1 uppercase font-medium !w-[70%] !text-base">
												More
											</Button>
										</Link>
									</div>
								</div>
							))
						}
					</div>
				</div>
			</div>
		</div>
	);
};

Foundations.propTypes = {
	foundations: PropTypes.array,
};

Foundations.defaultProps = {
	foundations: []
};
export default Foundations;