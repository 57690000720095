import React, { useState, useEffect } from "react";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { Hero, AboutUs, WhatWeDo, Foundations, Roadmap } from "layout/Home";
import API from 'api/Api';
import Cache from "utils/cache";
import * as loader from "components/Loading/loading";

const Home1Page = () => {
	const [foundations, setFoundations] = useState([]);

	const getProfile = (token) => {
		loader.showLoader();
		API.getUseToken('aix/profile', token).then(response => {
			Cache.setUser(response.data);
			Cache.setAccessToken(response.data.token);
			loader.hideLoader();
		}).catch(error => {
			console.log(error);
			loader.hideLoader();
		});
	};

	const getAPIInstitutions = () => {
		API.get('foundations?limit=10&page=1&filter=').then(response => {
			setFoundations(response.data.foundations);
		}).catch(error => {
			console.log(error);
		});
	};

	useEffect(() => {
		const urlParams = new URLSearchParams(window.location.search);
		const token = decodeURIComponent(urlParams.get('xtoken'));
		if (token) {
			const platform = urlParams.get('platform');
			Cache.setData({ token, platform });
			getProfile(token);
		}
		getAPIInstitutions();
	}, []);

	return (
		<div className="flex flex-col items-center justify-start w-full bg-white-A700">
			<div className="relative w-full">
				<Header
					showBackButton={ false }
					token={ decodeURIComponent(new URLSearchParams(window.location.search).get('xtoken')) }
					platform={ decodeURIComponent(new URLSearchParams(window.location.search).get('platform')) } />
			</div>
			<Hero />
			<AboutUs />
			<WhatWeDo />
			<Roadmap />
			{ foundations.length > 0 && <Foundations foundations={ foundations } /> }
			<Footer
				platform={ decodeURIComponent(new URLSearchParams(window.location.search).get('platform')) } />
		</div>
	);
};

export default Home1Page;
